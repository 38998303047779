import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import VuetifyMask from "vuetify-mask";
import VueSnip from 'vue-snip'
import store from "./store/store";
import moment from 'moment-timezone'
import 'moment/locale/ru'
Vue.use(require('vue-moment'), {
  moment
})

import auth                  from '@websanova/vue-auth/dist/v2/vue-auth.esm.js';
import driverAuthBearer      from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios       from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';
import axios from "axios"
import VueAxios from 'vue-axios'
// if(process.env.NODE_ENV === 'development') {
   // axios.defaults.baseURL = 'http://10.0.1.209:96/v1';
  // axios.defaults.baseURL = 'https://psapi.ao-nk.ru/v1'
  // axios.defaults.apiURL = 'https://psapi.ao-nk.ru/'
axios.defaults.baseURL = 'https://psapi.expertspk.ru/v1'
axios.defaults.apiURL = 'https://psapi.expertspk.ru/'
// } else {
//   axios.defaults.baseURL = 'https://psapi.ao-nk.ru/v1'
//   axios.defaults.apiURL = 'https://psapi.ao-nk.ru/'
// }
import aonkId from './plugins/aonkId.js';
// import vue-panzoom
import panZoom from 'vue-panzoom'

// install plugin
Vue.use(panZoom);

Vue.prototype.$checkRole = function (category,role,targetId=false) {
  if (Vue.auth.user().isAdmin) {
    if(category=='nark'){
      return false
    } else {
      return true
    }
    
  }
  let userRoles = Vue.auth.user().roles.filter(el => el.type==category && el.role==role)
  if(targetId){
    userRoles=userRoles.filter(el => el.targetId==targetId)
  }
  userRoles=userRoles.map(el=>el.role)
  return userRoles.includes(role)
}
Vue.prototype.$checkRoles = function (searchRoles) {
  for(let roleData of searchRoles){
    if(this.$checkRole(roleData.category,roleData.role,roleData.targetId)){return true}
  }
  return false
}
Vue.prototype.$checkCouncilAction = function (service,councilId) {
  if (Vue.auth.user().isAdmin) {
    
    return true
  }
  if(!councilId){
    return Vue.auth.user().councils.findIndex(el=>el.services.includes(service))!=-1
  } else {
    return Vue.auth.user().councils.findIndex(el=>el.services.includes(service) && el.id==councilId)!=-1
  }
}
import router from './router'
import VueParticles from 'vue-particles'
Vue.use(VueParticles)
Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueSnip)
Vue.use(VuetifyMask);
Vue.use(auth, {

  plugins: {
    http: axios,
    router: router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
    oauth2: {
      aonkId: aonkId,
    }
  },
  options: {
    authRedirect: '/login',
    notFoundRedirect: '/',
  }
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
